.ide {
  counter-reset: line-no 0;
  padding: 4px 0;
}
.ide-line::before{
  content: counter(line-no);
  width: 30px;
  display: block;
  margin-right: 8px;
  text-align: right;
  color: #5a646f;
  flex-shrink: 0;
}
.ide-line{
  line-height: 26px;
  counter-increment: line-no 1;
  display: flex;
  font-weight: 500;
  font-size: 16px;
}
.ide-line .symbol {
  color: #6c6783;
  font-weight: 400;
}
.ide-line .tag {
  color: #fff;
}
.ide-line .attr {
  color: #ffcc99;
}
.ide-line .indent::before{
  content: '\b7';
  color: #342f47;
}
.ide-line .key {
  color: #9a86fd;
}
.ide-line .value {
  color: #ffcc99;
}