.editor {
  width: calc(50vw - 1px);
  height: calc(100vh - 51px);
  border-right: 1px solid #27313a;
  display: flex;
  flex-direction: column;
}
.editor-header {
  background: #2e3a44;
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #323f4a;
}
.editor-header-title{
  flex: 1;
  font-weight: 700;
}
.editor-header-mode-selector {
  display: flex;
}
.editor-header-mode-selector > div {
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.editor-header-mode-selector > div:hover,.editor-header-mode-selector > div.active {
  background: #171d23;
  color: #fff;
}
.editor-ide {
  flex: 1;
}